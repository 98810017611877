<template>
  <l-table-container>
    <template v-slot:search>
      <a-form ref="formRef" :model="search.data" :labelCol="{ style: { width: '100px' } }">
        <l-search-container @search="onSearch" @reset="onReset">
          <a-col v-bind="searchCol">
            <a-row type="flex" align="top" :gutter="10">
              <a-col :flex="1">
                <a-form-item label="变化时间" name="startTime">
                  <a-date-picker v-model:value="search.data.startTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="开始时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>

              <a-col> <div style="margin-top: 5px">~</div> </a-col>

              <a-col :flex="1">
                <a-form-item label="" name="endTime">
                  <a-date-picker v-model:value="search.data.endTime" :disabled-date="disabledDate" :allowClear="false" inputReadOnly placeholder="结束时间" style="width: 100%" value-format="YYYY-MM-DD" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="日志类型" name="logType">
              <a-select v-model:value="search.data.logType" placeholder="请选择日志类型" allowClear>
                <a-select-option :value="item.value" v-for="item in clubLogTypes" :key="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="俱乐部ID" name="clubId">
              <a-input v-model:value.number="search.data.clubId" placeholder="请输入俱乐部ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="游戏ID" name="maskId">
              <a-input v-model:value.number="search.data.maskId" placeholder="请输入游戏ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="searchCol">
            <a-form-item label="操作人游戏ID" name="opertionMaskId">
              <a-input v-model:value.number="search.data.opertionMaskId" placeholder="请输入操作人游戏ID" autocomplete="off" allowClear />
            </a-form-item>
          </a-col>
        </l-search-container>
      </a-form>
    </template>

    <a-spin :spinning="loading">
      <a-list :grid="{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3, xxxl: 3 }" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-card :title="getLabelByValue(clubLogTypes, item.logType)" hoverable :body-style="{ padding: '5px 10px' }" :head-style="{ padding: '5px 10px' }">
              <a-descriptions title="" size="small" :label-style="{ fontWeight: 'bold' }">
                <a-descriptions-item label="俱乐部">{{ item.clubId }} -- {{ item.clubName }}</a-descriptions-item>
                <a-descriptions-item label="操作内容">{{ item.content }}</a-descriptions-item>
                <a-descriptions-item label="被操作玩家">{{ item.maskId }} -- {{ item.nickName }} </a-descriptions-item>
                <a-descriptions-item label="操作人">{{ item.opertionMaskId }} -- {{ item.opertionName }} </a-descriptions-item>
                <a-descriptions-item label="操作时间">{{ item.recordTime }}</a-descriptions-item>
              </a-descriptions>

              <!-- <template #actions>
                <l-auth-text-button type="danger" auth="us_club_white_list_del_btn" primary @click="del(item)">
                  <DeleteFilled />
                  移除小黑屋
                </l-auth-text-button>
              </template> -->
            </a-card>
          </a-list-item>
        </template>
      </a-list>

      <div style="text-align: right">
        <a-pagination v-model:current="pagination.current" size="small" v-model:page-size="pagination.pageSize" :total="pagination.total" :show-total="pagination.showTotal" @change="handleTableChange" />
      </div>
    </a-spin>

    <!-- <l-table @reload="onSearch" :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" row-key="index" :loading="loading">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'icon'">
          <a-avatar :size="30" :src="record.icon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'opertionIcon'">
          <a-avatar :size="30" :src="record.opertionIcon"> </a-avatar>
        </template>

        <template v-if="column.dataIndex === 'logType'">
          {{ getLabelByValue(clubLogTypes, record.logType) }}
        </template>

        <template v-if="column.dataIndex === 'identity'">
          {{ getLabelByValue(clubMemberIdentitys, record.identity) }}
        </template>

        <template v-if="column.dataIndex === 'opertionIdentity'">
          {{ getLabelByValue(clubMemberIdentitys, record.opertionIdentity) }}
        </template>
      </template>
    </l-table> -->
  </l-table-container>
</template>

<script lang="ts">
import http from "@/common/http";
import { clubLogTypes, getLabelByValue, clubMemberIdentitys } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import { dateIsBetween, getPageOffset } from "@/common/utils";
import { Dayjs } from "dayjs";

const columns = [
  // {
  //   title: "序号",
  //   width: 80,
  //   dataIndex: "index",
  //   fixed: "left",
  // },

  {
    title: "日志类型",
    width: 130,
    dataIndex: "logType",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "操作内容",
    width: 500,
    dataIndex: "content",
    ellipsis: true,
  },

  {
    title: "俱乐部ID",
    width: 80,
    dataIndex: "clubId",
    ellipsis: true,
  },

  {
    title: "俱乐部名称",
    width: 120,
    dataIndex: "clubName",
    ellipsis: true,
    // fixed: "left",
  },

  {
    title: "userID",
    width: 100,
    dataIndex: "userId",
    ellipsis: true,
  },

  {
    title: "游戏ID",
    width: 100,
    dataIndex: "maskId",
    ellipsis: true,
  },

  {
    title: "昵称",
    width: 160,
    dataIndex: "nickName",
    ellipsis: true,
  },

  {
    title: "身份",
    width: 100,
    dataIndex: "identity",
    ellipsis: true,
  },

  {
    title: "头像",
    width: 70,
    dataIndex: "icon",
    ellipsis: true,
  },

  {
    title: "等级",
    width: 70,
    dataIndex: "level",
    ellipsis: true,
  },

  {
    title: "操作人userID",
    width: 100,
    dataIndex: "opertionId",
    ellipsis: true,
  },

  {
    title: "操作人游戏ID",
    width: 140,
    dataIndex: "opertionMaskId",
    ellipsis: true,
  },

  {
    title: "操作人昵称",
    width: 140,
    dataIndex: "opertionName",
    ellipsis: true,
  },

  {
    title: "操作人身份",
    width: 120,
    dataIndex: "opertionIdentity",
    ellipsis: true,
  },

  {
    title: "操作人头像",
    width: 100,
    dataIndex: "opertionIcon",
    ellipsis: true,
  },

  {
    title: "操作人等级",
    width: 100,
    dataIndex: "opertionLevel",
    ellipsis: true,
  },

  {
    title: "操作时间",
    width: 180,
    dataIndex: "recordTime",
    ellipsis: true,
    fixed: "right",
  },

  // {
  //   title: "",
  //   key: "operation",
  //   align: "center",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 180,
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {
    // Edit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        maskId: undefined,
        userId: undefined,
        clubId: undefined,
        opertionMaskId: undefined,
        opertionId: undefined,
        startTime: "",
        endTime: "",
        logType: undefined,
        // nickName: "",
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const formRef = ref();

    //开始结束时间
    const validTimeRange = reactive({
      minDate: "",
      maxDate: "",
    });

    //获取不可选择的时间
    const disabledDate = (currentDate: Dayjs) => {
      return !dateIsBetween(validTimeRange.minDate, validTimeRange.maxDate, currentDate);
    };

    //首次进入，获取可选的日期范围
    const getTime = async () => {
      loading.value = true;
      const { data } = await http.get("us_club_opertion_log/time");
      validTimeRange.minDate = data.minDate;
      validTimeRange.maxDate = data.maxDate;
      search.data.startTime = data.maxDate;
      search.data.endTime = data.maxDate;

      onSearch();
    };

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("us_club_opertion_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      let offset = getPageOffset(pagination.current!, pagination.pageSize!);
      data.value = result.data.data.map((item: any) => {
        item.index = ++offset;
        return item;
      });

      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    // const handleTableChange = (pag: PaginationProps) => {
    //   pagination.current = pag?.current;
    //   pagination.pageSize = pag?.pageSize;
    //   getList();
    // };

    const handleTableChange = (pag: number, pageSize: number) => {
      pagination.current = pag;
      pagination.pageSize = pageSize;
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      search.data.startTime = validTimeRange.maxDate;
      search.data.endTime = validTimeRange.maxDate;
      onSearch();
    };

    onMounted(() => {
      getTime();
    });

    return {
      data,
      columns,
      search,
      pagination,
      formRef,
      loading,
      handleTableChange,
      onSearch,
      onReset,
      searchCol,
      getLabelByValue,
      clubLogTypes,
      clubMemberIdentitys,
      disabledDate,
    };
  },
});
</script>
